var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      staticClass: "py-10",
      attrs: {
        footer: {
          text: "Need help?",
          linkTitle: "Contact support",
          link: "mailto:support@xinfin.org",
        },
        title: _vm.title,
        "show-overlay": _vm.onSettings,
        back: _vm.editMode || _vm.addMode ? _vm.back : null,
        "content-size": "xlarge",
        close: _vm.close,
      },
    },
    [
      !_vm.editMode && !_vm.addMode
        ? _c("mew-expand-panel", {
            staticClass: "mt-6",
            attrs: {
              "panel-items": _vm.panelItems,
              "idx-to-expand": _vm.idxToExpand,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "panelBody1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "px-5" },
                        [
                          _c("settings-gas-price", {
                            attrs: {
                              buttons: _vm.gasButtons,
                              selected: _vm.gasPriceType,
                              "set-selected": _vm.setSelected,
                              global: true,
                              "from-settings": true,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "panelBody2",
                  fn: function () {
                    return [
                      _c("settings-import-config", {
                        attrs: { "import-config": _vm.settingsHandler },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "panelBody3",
                  fn: function () {
                    return [
                      _c("settings-export-config", {
                        attrs: { "export-config": _vm.exportStore },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "panelBody4",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pa-6" },
                        [
                          _c("div", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("interface.address-book.add-up-to")
                                ) +
                                " "
                            ),
                          ]),
                          _c("settings-address-table", {
                            attrs: { "table-data": _vm.tableData },
                            on: { onClick: _vm.onEdit },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center mt-5" },
                            [
                              _c("mew-button", {
                                attrs: {
                                  disabled: _vm.addressBookStore.length >= 10,
                                  title: "+ Add",
                                  "btn-size": "xlarge",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.addMode = !_vm.addMode
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "panelBody5",
                  fn: function () {
                    return [_c("settings-locale-config")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              51024635
            ),
          })
        : _vm._e(),
      _vm.online && !_vm.addMode && !_vm.editMode
        ? _c("div", { staticClass: "mt-3 px-8" }, [
            _c(
              "div",
              { staticClass: "matomo-tracking-switch" },
              [
                _c("v-switch", {
                  attrs: {
                    label:
                      "Data Sharing is " + (_vm.dataSharingOn ? "on" : "off"),
                    "input-value": _vm.consentToTrack,
                    inset: "",
                    color: "greenPrimary",
                    "off-icon": "mdi-alert-circle",
                  },
                  on: { change: _vm.setConsent },
                  model: {
                    value: _vm.dataSharingOn,
                    callback: function ($$v) {
                      _vm.dataSharingOn = $$v
                    },
                    expression: "dataSharingOn",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.addMode || _vm.editMode
        ? _c("address-book-add-edit", {
            staticClass: "mt-4 mt-lg-0",
            attrs: { item: _vm.itemToEdit, mode: _vm.onMode },
            on: { back: _vm.back },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }