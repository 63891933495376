var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { on: { click: _vm.copyToClipboard } }, [_vm._t("default")], 2),
      _c("mew-toast", {
        ref: "toast",
        attrs: {
          duration: 2000,
          "toast-type": "success",
          text: _vm.successToast,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }